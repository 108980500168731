import Vue from 'vue';
import VueRouter from 'vue-router';

// Routes
import { canNavigate } from '@/libs/acl/routeProtection';
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
  initialRoutes,
} from '@/auth/utils';
import apps from './routes/apps';
import upper_common from './routes/upper_common';
import common from './routes/common';
import recruiting from './routes/recruiting';
import accounting from './routes/accounting';
import hr from './routes/hr';
import engagement from './routes/engagement';
import onboarding from './routes/onboarding';
import candidate from './routes/candidate';
import employee from './routes/employee';
import dashboard from './routes/dashboard';
import uiElements from './routes/ui-elements/index';
import pages from './routes/pages';
import admin from './routes/admin';


import useAuth from '@/auth/useAuth';

Vue.use(VueRouter);

let homeRedirectRoute = getHomeRouteForLoggedInUser();


export function updateRoutes(user) {
  // You need to determine getRoleRoutes method based on your application's requirements
  let roleRoutes = getRoleRoutes(user);

  // Reset and add new routes
  router.options.routes = roleRoutes;
  router.matcher = new VueRouter({ routes: [] }).matcher;
  router.addRoutes(roleRoutes);
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      redirect: {
        path: homeRedirectRoute,
      },
    },
    {
      path: '/debug',
      name: 'debugger',
      component: () => import ('@/views/debug/debug.vue')
    },
    ...apps,//DONE
    ...upper_common,
    ...common,
    ...accounting,
    ...recruiting,
    ...engagement,
    ...onboarding,
    ...hr,
    ...engagement,
    ...candidate,
    ...employee,

    ...dashboard,
    ...pages,
    ...admin,//DONE
    ...uiElements,

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

router.beforeEach((to, _, next) => {
  if(process.env.VUE_APP_MAINTENANCE_MODE === 'true' && to.name !== 'under-maintenance') {
    return next({name: 'under-maintenance'});
  } else {
    const isLoggedIn = isUserLoggedIn();
    // console.log("Navigating to: ", to);
    // console.log("Role: ", useAuth.getUserType());
    // console.log("Can navigate to: ", to.name, canNavigate(to));
    // debugger;
    if (!canNavigate(to)) {
      // Can't navigate because not logged-in -> go to login
      if (!isLoggedIn) {
        return next({name: 'auth-login'});
      }

      // If logged but not authorized, redirect to standard page
      return next({name: 'misc-not-authorized'});
    }
    
    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      const userData = getUserData();
      homeRedirectRoute = getHomeRouteForLoggedInUser(userData ? userData.role : null)
      next(homeRedirectRoute);
    }

    return next();
  }
});

export default router;
